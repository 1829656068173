import React from 'react'
import { useTranslation } from 'react-i18next'
import advantageIcon1 from '../assets/advantage-icon1.svg'
import advantageIcon2 from '../assets/advantage-icon2.svg'
import advantageIcon3 from '../assets/advantage-icon3.svg'
import advantageIcon4 from '../assets/advantage-icon4.svg'

const AdvantageBlock = () => {
  const { t } = useTranslation()
  return (
    <div className='advantage container'>
      <div className='advantage_items'>
        <div className='advantage_item'>
          <div className='advantage_item-icon item-icon box-shadow'>
            <img src={advantageIcon1} alt='' />
          </div>
          <div className='advantage_item-desc'>{t('Мы работаем 24/7')}</div>
        </div>
        <div className='advantage_item'>
          <div className='advantage_item-icon item-icon box-shadow'>
            <img src={advantageIcon2} alt='' />
          </div>
          <div className='advantage_item-desc'>
            {t('Более 10 банков используют наш сервис на своих сайтах')}
          </div>
        </div>
        <div className='advantage_item'>
          <div className='advantage_item-icon item-icon box-shadow'>
            <img src={advantageIcon3} alt='' />
          </div>
          <div className='advantage_item-desc'>
            {t('Принимаем карты всех Банков Республики Азербайджан')}
          </div>
        </div>
        <div className='advantage_item'>
          <div className='advantage_item-icon item-icon box-shadow'>
            <img src={advantageIcon4} alt='' />
          </div>
          <div className='advantage_item-desc'>
            {t('Перевод денег за 1 минуту')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvantageBlock
