import { LanguageActionTypes } from '../types'

const language = (state, { type, payload }) => {
  switch (type) {
    case LanguageActionTypes.CHANGE_LANGUAGE:
      return { ...state, selectedLanguage: payload }
    default:
      return state
  }
}

export default language
