import React from 'react'
import { Languages } from '.'

const Header = () => {
  return (
    <header className='site-header-wrapper'>
      <div className='site-header container'>
        <Languages />
      </div>
    </header>
  )
}

export default Header
