import React from 'react'
import { getQueryParams } from './util'
import { Loader } from '.';

const host = window.location.protocol + "//" + window.location.host;

class ResultPage extends React.Component {
  state = {
  }

  componentDidMount() {
    this.commmit()
  }

  async commmit() {
    try {
      const queryParams = getQueryParams();
      const res = await fetch(host + "/api/c2c/commit", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refernceNo: queryParams.refernceNo,
          token: queryParams.token,
          currency: 'AZN',
          customFields: [],
        }),
      })
      if (res.status !== 200) {
        window.location = "/error";
        return;
      }
      window.location = "/success";
    } catch (e) {
      window.location = "/error";
    }
  }

  render() {
    return (
      <>
        <Loader />
      </>
    )
  }
}

export default ResultPage
