import React, { useContext } from 'react'
import {
  AdditionalInformationBlock,
  AdvantageBlock,
  CardToCardFormBlock,
  // CardToCartInfoBlock,
  DefenseBlock,
  Header,
  LimitBlock,
} from '.'

import LocalesContext from '../context/locales/context'

const MainPage = () => {
  const {
    state: { selectedLanguage },
  } = useContext(LocalesContext)

  return (
    <>
      <Header />
      <CardToCardFormBlock selectedLanguage={selectedLanguage} />
      <AdvantageBlock />
      <LimitBlock />
      <DefenseBlock />
      {/* <CardToCartInfoBlock /> */}
      <AdditionalInformationBlock />
    </>
  )
}

export default MainPage
