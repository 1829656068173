import React, { useContext } from 'react'
import LocalesContext from '../context/locales/context'
import { allLanguages } from '../context/locales/state'

const Languages = () => {
  const {
    state: { selectedLanguage },
    changeLanguage,
  } = useContext(LocalesContext)

  return (
    <div className='languages'>
      {allLanguages.map((language, i) => (
        <button
          key={i}
          className={
            selectedLanguage === language
              ? 'languages_link languages_link--active'
              : 'languages_link'
          }
          onClick={() => {
            changeLanguage(language)
          }}
        >
          {language}
        </button>
      ))}
    </div>
  )
}

export default Languages
