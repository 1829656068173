import React from 'react'
import { useTranslation } from 'react-i18next'

const AdditionalInformationBlock = () => {
  const { t } = useTranslation()
  return (
    <div className='additional-information wrapper-block'>
      <div className='additional-information container'>
        <div className='additional-information_title block-title title'>
          {t('Дополнительная информация')}
        </div>
        <div className='additional-information_sub-title block-sub-title'>
          {t(
            'Сервис smartcard2card предлагает удобную, а главное - безопасную услугу быстрых денежных переводов на карту выпущенную любым банком Республики Азербайджан.',
          )}
        </div>
        <div className='additional-information_items'>
          <div className='additional-information_item'>
            <div className='additional-information_item-title item-title title '>
              {t('Как сделать перевод на карту любого банка?')}
            </div>
            <div className='additional-information_item-body'>
              <div className='additional-information_item-body-item'>
                {t(
                  'Воспользоваться услугой переводов с карты на карту достаточно просто. Введите следующие данные:',
                )}
              </div>
              <div className='additional-information_item-body-item'>
                <ul className='additional-information_item-body-item-list'>
                  <li className='additional-information_item-body-item-list-item'>
                    {t(
                      '16-ти значный номер Вашей банковской карты, её CVV-код и срок действия;',
                    )}
                  </li>
                  <li className='additional-information_item-body-item-list-item'>
                    {t('Ваш мобильный номер;')}
                  </li>
                  <li className='additional-information_item-body-item-list-item'>
                    {t('Сумму перевода;')}
                  </li>
                  <li className='additional-information_item-body-item-list-item'>
                    {t(
                      '16-ти значный номер карты на которую будет зачислен перевод, а также, при желании, контакты получателя для того чтобы мы могли оповестить его о зачислении средств на счет.',
                    )}
                  </li>
                </ul>
              </div>
              <div className='additional-information_item-body-item'>
                {t(
                  'Всего за пару минут перевод будет выполнен и средства поступят на указанную Вами карту!',
                )}
              </div>
            </div>
          </div>

          <div className='additional-information_item'>
            <div className='additional-information_item-title item-title title'>
              {t('Наши тарифы и условия')}
            </div>
            <div className='additional-information_item-body'>
              <div className='additional-information_item-body-item'>
                {t(
                  'Сервис smartcard2card предлагает самые выгодные тарифы. Комиссия составит 1% от суммы перевода. При этом комиссию оплачивает отправитель.',
                )}
              </div>
              <div className='additional-information_item-body-item'>
                {t(
                  'Даже если получатель в данный момент находится за пределами страны, деньги будут зачислены на его банковскую карту.',
                )}
              </div>
            </div>
          </div>
          <div className='additional-information_item'>
            <div className='additional-information_item-title item-title title'>
              {t('Гарантия защиты Ваших данных')}
            </div>
            <div className='additional-information_item-body'>
              <div className='additional-information_item-body-item'>
                {t(
                  'Все операции, совершаемые на сайте smartcard2card.com находятся под защитой технологии 3D-Secure. Введенные Вами данные тщательно шифруются в соответствии с протоколом PCI DSS, который был разработан ведущими мировыми системами. Сервис smartcard2card использует анти-фрод систему, распознающую и блокирующую любые попытки мошеннических транзакций.',
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalInformationBlock
