import React from 'react'
import { useTranslation } from 'react-i18next'
import defenseIcon1 from '../assets/defense-icon-1.svg'
import defenseIcon2 from '../assets/defense-icon-2.svg'
import defenseIcon3 from '../assets/defense-icon-3.svg'

const Defense = () => {
  const { t } = useTranslation()
  return (
    <div className='defense container'>
      <div className='defense_title block-title title'>
        {t('Мы защищаем ваши данные')}
      </div>
      <div className='defense_sub-title block-sub-title'>
        {t(
          'Все операции, совершаемые на сайте smartcard2card.com находятся под защитой технологии 3D-Secure',
        )}
      </div>
      <div className='defense_icons'>
        <div className='defense_icons-item'>
          <img src={defenseIcon1} alt='' />
        </div>
        <div className='defense_icons-item'>
          <img src={defenseIcon2} alt='' />
        </div>
        <div className='defense_icons-item'>
          <img src={defenseIcon3} alt='' />
        </div>
      </div>
    </div>
  )
}

export default Defense
