import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ErrorPage, SuccessPage, ResultPage, MainPage } from '.'
import LocalesState from '../context/locales/state'

const App = () => {
  const routes = [
    { path: '/', component: MainPage, exact: true },
    { path: '/error', component: ErrorPage, exact: true },
    { path: '/success', component: SuccessPage, exact: true },
    { path: '/result', component: ResultPage, exact: true },
  ]

  return (
    <LocalesState>
      <Switch>
        {routes.map(({ path, exact, component }) => (
          <Route component={component} path={path} exact={exact} key={path} />
        ))}
      </Switch>
    </LocalesState>
  )
}

export default App
