import React from 'react'
import { useTranslation } from 'react-i18next'

const LimitBlock = () => {
  const { t } = useTranslation()
  return (
    <div className='limit-wrapper wrapper-block'>
      <div className='limit container'>
        <div className='limit_title block-title title'>
          {t('Лимиты и ограничения')}
        </div>
        <div className='limit_sub-title block-sub-title'>
          {t('Переводы с карты на карту по Республике Азербайджан')}
        </div>
        <div className='limit_items'>
          <div className='limit_item box-shadow'>
            <div className='limit_item-title item-title title'>15 000 AZN</div>
            <div className='limit_item-body'>
              {t('Максимальная сумма перевода')}
            </div>
          </div>
          <div className='limit_item box-shadow'>
            <div className='limit_item-title item-title title'>15 000 AZN</div>
            <div className='limit_item-body'>
              {t('Максимальная сумма переводов в сутки')}
            </div>
          </div>
          <div className='limit_item box-shadow'>
            <div className='limit_item-title item-title title'>15 000 AZN</div>
            <div className='limit_item-body'>
              {t('Максимальная сумма переводов за месяц')}
            </div>
          </div>
          <div className='limit_item box-shadow'>
            <div className='limit_item-title item-title title'>15</div>
            <div className='limit_item-body'>
              {t('Максимальное количество всех переводов в сутки')}
            </div>
          </div>
          <div className='limit_item box-shadow'>
            <div className='limit_item-title item-title title'>15</div>
            <div className='limit_item-body'>
              {t('Максимальное количество всех переводов за месяц')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LimitBlock
