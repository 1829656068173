import React from 'react'
import { withTranslation } from 'react-i18next'

import InputMask from 'react-input-mask'

import CreditCardInput from 'react-credit-card-input'

import { Loader } from '.'

import conditionDocument from '../assets/e33898de-6302-4756-8f0c-5f6c5218e02e_terms_ru.pdf'

import { getQueryParams, parseQuery } from './util'

const MFS = window.MFS
const $ = window.$

const host = window.location.protocol + '//' + window.location.host

MFS.setAddress('https://ui.masterpassturkiye.com/v2')
class CardToCardFormBlock extends React.Component {
  state = {
    fromCard: '',
    expiryDate: '',
    cvc: '',
    toCard: '',
    phone: '',
    amount: '',
    conditions: false,
    loading: false,
    formValid: false,
    amountValid: false,
  }

  minAmount = 1
  maxAmount = 15000

  loadingState = loadingValue => {
    this.setState({ loading: loadingValue })
  }

  registerPayment = async (
    fromCard,
    expiryDate,
    cvc,
    toCard,
    amount,
    phone,
  ) => {
    const queryParams = getQueryParams()
    const id = queryParams.id
    const merchantId = queryParams.merchantId
    const signature = queryParams.signature
    try {
      this.loadingState(true)
      const res = await fetch(host + '/api/c2c/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pan: toCard.replaceAll(' ', ''),
          amount,
          id,
          merchantId,
          signature,
          phone,
        }),
      })

      if (res.status !== 200) {
        this.showError('Error!')
        return
      }

      const data = await res.json()

      MFS.setClientId(data.masterPassClient)

      this.referenceNumber = data.referenceNumber
      this.amount = amount
      this.macroMerchantId = data.masterPassMerchantId

      document.purchaseForm.msisdn.value = data.msisdn
      document.purchaseForm.amount.value = amount
      document.purchaseForm.rtaPan.value = fromCard.replaceAll(' ', '')
      document.purchaseForm.expiryDate.value = expiryDate
        .split('/')
        .map(s => s.trim())
        .reverse()
        .join('')
      document.purchaseForm.cvc.value = cvc

      document.purchaseForm.referenceNo.value = data.referenceNumber
      document.purchaseForm.orderNo.value = data.referenceNumber
      document.purchaseForm.macroMerchantId.value = data.masterPassMerchantId
      document.purchaseForm.token.value = data.token

      this.setState(data, () => {
        MFS.directPurchase($('#purchase-form'), this.mfsResponseHandler)
      })
    } catch (e) {
      this.showError(e.message)
    }
  }

  saveToken = async (token, jsonObject) => {
    try {
      this.loadingState(true)
      const res = await fetch(host + '/api/c2c/saveToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jsonObject,
          token: token,
          referenceNumber: this.referenceNumber,
        }),
      })
      if (res.status !== 200) {
        this.showError('Error!')
        return
      }
    } catch (e) {
      this.showError(e.message)
    }
  }

  async commmit(token) {
    try {
      const res = await fetch(host + '/api/c2c/commit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: this.amount,
          token: token,
          macroMerchantId: this.macroMerchantId,
          currency: 'AZN',
          customFields: [],
        }),
      })
      if (res.status !== 200) {
        this.showError('Error!')
        return
      }
    } catch (e) {
      this.showError(e.message)
    }
  }

  mfsResponseHandler = async (status, response) => {
    let pinType = 'otp'

    let token = response.token
    if ((response.responseCode = '5010')) {
      token = MFS.getLastToken();
    }
    await this.saveToken(token, JSON.stringify(response))

    switch (response.responseCode) {
      case '':
      case '0000':
        this.loadingState(false)
        await this.commmit(response.token)
        window.location = `${host}/success`
        break
      case '5000': // RTA
        pinType = 'rta'
      case '5015': // MPIN
      case '5008': // Ask Masterpass OTP
      case '5001': // Ask OTP
        this.loadingState(false)
        if (response.responseCode === '5015') {
          pinType = 'mpin'
        }
        this.setState({
          isModalVisible: !this.state.isModalVisible,
          pinType,
          referenceNo: response.referenceNo,
        })
        break
      case '5010': // 3ds
        window.location = `${response.url3D}&returnUrl=${host}/result?refernceNo=${this.referenceNumber}`
        break
      case '1419':
        this.showError(response.responseDescription)
        break
      case '5192':
        this.showError(response.responseDescription)
        break
      case '1999':
        this.showError(response.responseDescription)
        break
      case '5460':
        this.showError(response.responseDescription)
        break
      case '5195':
        this.showError(response.responseDescription)
        break
      case '5196':
        this.showError(response.responseDescription)
        break
      default:
        console.log('Unknown payment error', JSON.stringify(response))
        this.showError('Unknown payment error')
        break
    }
  }

  showError = errorText => {
    this.loadingState(false)
    window.alert('', errorText)
  }

  validateTransaction = validationCode => {
    try {
      document.otpForm.validationCode.value = validationCode
      document.otpForm.referenceNo.value = this.state.referenceNo
      document.otpForm.pinType.value = this.state.pinType
      MFS.validateTransaction($('#otp-form'), this.mfsResponseHandler)
    } catch (error) {
      this.showError(error.message)
    }
  }

  handleFromCardNumberChange = e => {
    this.setState({ formValid: true })
    this.setState({ fromCard: e.target.value })
  }

  handleCardExpiryChange = e => {
    this.setState({ expiryDate: e.target.value, formValid: true })
  }

  handleCardCVCChange = e => {
    this.setState({ cvc: e.target.value, formValid: true })
  }

  handleToCardNumberChange = e => {
    this.setState({ toCard: e.target.value, formValid: true })
  }

  handleFromCardError = () => {
    this.setState({ formValid: false })
  }

  isDisabledBtn = () => {
    const stateFormKeysArray = [
      'fromCard',
      'expiryDate',
      'cvc',
      'toCard',
      'amount',
    ]

    for (let i = 0; i < stateFormKeysArray.length; i++) {
      if (!this.state[stateFormKeysArray[i]]) return true
    }

    return false
  }

  componentDidUpdate = (_, prevS) => {
    if (prevS.amount !== this.state.amount) {
      if (
        this.state.amount < this.minAmount ||
        this.state.amount > this.maxAmount
      ) {
        return this.setState({ amountValid: false })
      }
      return this.setState({ amountValid: true })
    }
  }

  render() {
    const { t, selectedLanguage } = this.props

    const {
      fromCard,
      expiryDate,
      cvc,
      toCard,
      phone,
      amount,
      conditions,
      loading,
      formValid,
      amountValid,
    } = this.state

    const countCommit = () => +amount * 0.01

    const customTextLabels = {
      invalidCardNumber: t('Номер карты недействителен'),
      expiryError: {
        invalidExpiryDate: t('Неверный срок действия'),
        monthOutOfRange: t('Месяц вне диапазона'),
        yearOutOfRange: t('Год вне допустимого диапазона'),
        dateOutOfRange: t('Дата вне диапазона'),
      },
      invalidCvc: t('Код CVC недействителен'),
      cardNumberPlaceholder: t('Номер карты'),
    }

    return (
      <div className='card-form-wrapper wrapper-block'>
        {loading && <Loader />}
        <div className='card-form container'>
          <div className='card-form_title block-title title'>
            {t('Перевод денег с карты на карту')}
          </div>
          <div className='card-form_sub-title'>
            {t(
              'Мгновенные переводы с карты на карту любого банка Республики Азербайджан',
            )}
          </div>
          <div className='card-form_items'>
            <div className='card-form_item-wrapper card-form-left-part'>
              <div className='card-form_item-title item-title block-title title'>
                {t('С карты')}
              </div>
              <div className='card-form_item-inner box-shadow'>
                <div className='card-form_item-card-data'>
                  <CreditCardInput
                    customTextLabels={customTextLabels}
                    cardNumberInputProps={{
                      value: fromCard,
                      onChange: this.handleFromCardNumberChange,
                      onError: this.handleFromCardError,
                    }}
                    cardExpiryInputProps={{
                      value: expiryDate,
                      onChange: this.handleCardExpiryChange,
                      onError: this.handleFromCardError,
                    }}
                    cardCVCInputProps={{
                      value: cvc,
                      onChange: this.handleCardCVCChange,
                      onError: this.handleFromCardError,
                    }}
                    fieldClassName='input'
                  />
                </div>

                <div className='card-form_controls'>
                  <label
                    className='card-form_controls-label'
                    htmlFor='phone-input'
                  >
                    {t('Номер телефона')}
                  </label>
                  <InputMask
                    mask='099 999-99-99'
                    placeholder={'0XX XXX-XX-XX'}
                    onChange={event => {
                      let _value = event.target.value
                      if (_value.length === 13) {
                        _value = _value.replace(/ |-/g, '').substring(1)
                      }
                      this.setState({ phone: _value })
                    }}
                    id='phone-input'
                    value={phone}
                  />
                </div>
              </div>
              <div className='card-form_conditions box-shadow'>
                <label>
                  <input
                    type='checkbox'
                    id='conditions'
                    checked={conditions}
                    onChange={() => {
                      this.setState(prevS => ({
                        ...prevS,
                        conditions: !prevS.conditions,
                      }))
                    }}
                  />
                  {selectedLanguage === 'ru' ? (
                    <span>
                      Я ознакомился и принимаю условия{' '}
                      <a
                        href={conditionDocument}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        Пользовательского Соглашения.
                      </a>
                    </span>
                  ) : (
                    <span>
                      <a
                        href={conditionDocument}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        Istifadəçi Razilaşmasi
                      </a>{' '}
                      ilə tanış oldum və qəbul edirəm.
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className='arrow-icon box-shadow'>
              <svg
                width='54'
                height='44'
                viewBox='0 0 54 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.6758 32.6377L28.3136 21.9999'
                  strokeWidth='2.02'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
                <path
                  d='M28.3135 22L17.6757 11.3622'
                  strokeWidth='2.02'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
                <path
                  d='M27.6758 32.6377L38.3136 21.9999'
                  strokeWidth='2.02'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
                <path
                  d='M38.3135 22L27.6757 11.3622'
                  strokeWidth='2.02'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
            </div>
            <div className='card-form_item-wrapper  card-form-right-part'>
              <div className='card-form_item-title item-title block-title title'>
                {t('На карту')}
              </div>
              <div className='card-form_item-inner box-shadow'>
                <div className='card-form_item-card-data'>
                  <CreditCardInput
                    customTextLabels={customTextLabels}
                    cardNumberInputProps={{
                      value: toCard,
                      onChange: this.handleToCardNumberChange,
                      onError: this.handleFromCardError,
                    }}
                    fieldClassName='input'
                  />
                </div>

                <div className='card-form_controls'>
                  <div className='card-form_controls-item'>
                    <label
                      className='card-form_controls-label'
                      htmlFor='sum-input'
                    >
                      {t('Сумма перевода')}
                    </label>
                    <input
                      className={
                        !amountValid && amount
                          ? 'card-form_controls-input invalid'
                          : 'card-form_controls-input'
                      }
                      id='sum-input'
                      type='text'
                      value={amount}
                      onChange={e => {
                        this.setState({
                          amount: e.target.value
                            .replace(/[^.\d]+/g, '')
                            .replace(/^([^\.]*\.)|\./g, '$1'),
                        })
                      }}
                    />
                    {!amountValid && amount && (
                      <span className='card-form_addition amount-invalid'>
                        {t('Сумма должна быть от 1 Azn до 15000 Azn')}
                      </span>
                    )}
                    {amountValid && amount && (
                      <span className='card-form_addition amount-commit'>
                        {countCommit() < 1
                          ? `${t('Комиссия')}: 1 Azn`
                          : `${t('Комиссия')}: ${countCommit()} Azn`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='card-form_btn box-shadow'>
                <button
                  disabled={
                    this.isDisabledBtn() ||
                    !formValid ||
                    !amountValid ||
                    phone.length !== 9 ||
                    phone.includes('_') ||
                    !conditions
                  }
                  onClick={() =>
                    this.registerPayment(
                      fromCard,
                      expiryDate,
                      cvc,
                      toCard,
                      Number(amount) * 100,
                      phone,
                    )
                  }
                >
                  {t('Перевести')}
                </button>
              </div>
            </div>
          </div>

          <form action='' method='POST' id='otp-form' name='otpForm'>
            <input type='hidden' name='validationCode' id='otp-input' />

            <input type='hidden' id='referenceNo' name='referenceNo' />
            <input type='hidden' name='sendSms' value='N' />
            <input type='hidden' name='sendSmsLanguage' value='eng' />
            <input type='hidden' name='pinType' value='otp' />
          </form>

          <form action='' method='POST' id='purchase-form' name='purchaseForm'>
            <input type='hidden' name='amount' />

            <input type='hidden' id='card_no' name='rtaPan' />
            <input type='hidden' id='expiryDate' name='expiryDate' />
            <input type='hidden' id='iCVC' name='cvc' />

            <input type='hidden' id='msisdn' name='msisdn' />
            <input type='hidden' id='token' name='token' />
            <input type='hidden' id='referenceNo' name='referenceNo' />

            <input type='hidden' name='macroMerchantId' id='macroMerchantId' />
            <input type='hidden' name='orderNo' id='historyId' />

            <input
              type='hidden'
              id='sendSmsLanguage'
              name='sendSmsLanguage'
              value='eng'
            />
            <input type='hidden' name='sendSms' value='N' />
            <input type='hidden' name='actionType' value='A' />
            <input
              type='hidden'
              id='clientIp'
              name='clientIp'
              value='0.0.0.0'
            />
            <input type='hidden' name='delinkReason' value='' />
            <input type='hidden' name='eActionType' value='A' />
            <input type='hidden' name='cardTypeFlag' value='05' />
            <input type='hidden' name='cpinFlag' value='Y' />
            <input type='hidden' name='defaultAccount' value='Y' />
            <input type='hidden' name='mmrpConfig' value='110010' />
            <input type='hidden' name='identityVerificationFlag' value='Y' />
            <input type='hidden' name='mobileAccountConfig' value='MWA' />
            <input type='hidden' id='timeZone' name='timeZone' value='+4' />
            <input type='hidden' name='uiChannelType' value='6' />
          </form>
        </div>
      </div>
    )
  }
}

export default withTranslation()(CardToCardFormBlock)
