import React, { useReducer } from 'react'
import i18n from 'i18next'
import Context from './context'
import reducer from './reducer'
import { LanguageActionTypes } from '../types'

export const allLanguages = ['az', 'ru']

const State = ({ children }) => {
  const initialState = {
    selectedLanguage: 'az',
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const LanguageActionCreators = {
    _changeLanguage: newLang => ({
      type: LanguageActionTypes.CHANGE_LANGUAGE,
      payload: newLang,
    }),
    changeLanguage: newLang => {
      i18n.changeLanguage(newLang)
      dispatch(LanguageActionCreators._changeLanguage(newLang))
    },
  }

  return (
    <Context.Provider
      value={{
        state,
        changeLanguage: LanguageActionCreators.changeLanguage,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default State
