export function getQueryParams() {
    return parseQuery(window.location.search || '');
}

export function parseQuery(queryString) {
    let keyValPairs = [];
    queryString = queryString.replace(/.*?\?/,"");
 
    const params = {};
    if (queryString.length)
    {
       keyValPairs = queryString.split('&');
       for (const pairNum in keyValPairs)
       {
          var key = keyValPairs[pairNum].split('=')[0];
          if (!key.length) continue;
          params[key] = keyValPairs[pairNum].split('=')[1];
       }
    }
    return params;
}