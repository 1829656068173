import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './i18n.js'

import { App } from './Components'

import './scss/index.scss'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.querySelector('#site-wrapper'),
)
