import React from 'react'

const Loader = () => (
  <div className='loader-wrapper'>
    <div className='lds-ring'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

export default Loader
